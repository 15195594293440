// Version 1.3.0-10 2024-12-02

// Original code starts here
import React, { useState, useEffect } from 'react';
import SwitchComponent from './SwitchComponent';
import { fetchDataFromServer } from '../apiUtils';
import { useNavigate } from 'react-router-dom';

// Configuration for multiple HWs
const hwConfig = {
  HW1: {
    apikey: 'API-002',
    variables: ['HE_lamp_kert', 'HE_terasz', 'HE_szivattyu', 'HE_lamp_udvar', 'Restart_number', 'HE_kapu_1', 'He_ajto'],
  },
  HW2: {
    apikey: 'API-004',
    variables: ['HE_kapcsolo_1', 'HE_kapcsolo_2', 'HE_kapcsolo_3', 'HE_kapcsolo_4'],
  },
};

// Currently selected HW (can be dynamically switched)
const currentHW = hwConfig.HW1;

const HegyaljaPage = () => {
  const navigate = useNavigate();
  const [switches, setSwitches] = useState([
    { label: 'Kert', value: false, onColor: '#FF4500', offColor: '#39FF14' },
    { label: 'Terasz', value: false, onColor: '#FF4500', offColor: '#39FF14' },
    { label: 'Szivattyú', value: false, onColor: '#FF4500', offColor: '#39FF14' },
    { label: 'Udvar', value: false, onColor: '#FF4500', offColor: '#39FF14' },
  ]);
  const [heartbeat, setHeartbeat] = useState(false);
  const [lastRequestTime, setLastRequestTime] = useState('');
  const [serverValue, setServerValue] = useState(30);
  const [responseText, setResponseText] = useState('');
  const [temperatureBMP, setTemperatureBMP] = useState(null);
  const [temperatureAHT, setTemperatureAHT] = useState(null);
  const [pressBMP, setPressBMP] = useState(null);
  const [humiAHT, setHumiAHT] = useState(null);

  // New states
  const [restartNumber, setRestartNumber] = useState(-1); // Default value
  const [heKapu1, setHeKapu1] = useState(1); // Default value
  const [heAjto, setHeAjto] = useState('nyitva'); // Default value

  const toggleSwitch = (index) => {
    const updatedSwitches = switches.map((sw, i) =>
      i === index ? { ...sw, value: !sw.value } : sw
    );
    setSwitches(updatedSwitches);
    sendDataToServer(
      currentHW.variables[index],
      updatedSwitches[index].value ? 'true' : 'false'
    );
  };

  const changeValue = (amount) => {
    const newValue = Math.max(0, serverValue + amount);
    setServerValue(newValue);
    sendDataToServer('security_code', newValue);
  };

  const sendDataToServer = (variable, value) => {
    fetchDataFromServer(currentHW.apikey, variable, 'POST', value)
      .then((response) => {
        if (response) {
          setResponseText(`Válasz: ${JSON.stringify(response)}`);
        }
      })
      .catch((error) => {
        setResponseText(`Hiba: ${error.message}`);
      });
  };

  const fetchHeartbeat = () => {
    fetchDataFromServer(currentHW.apikey, 'HeartBeat', 'GET')
      .then((response) => {
        if (response && response.HeartBeat && response.HeartBeat.value !== undefined) {
          setHeartbeat(response.HeartBeat.value === 'true');
        }
        setLastRequestTime(new Date().toLocaleString());
      })
      .catch((error) => {
        console.error('Hiba a HeartBeat lekérdezésekor:', error);
      });
  };

  const fetchInitialState = () => {
    fetchDataFromServer(currentHW.apikey, '', 'POST')
        .then((response) => {
            if (response) {
                setSwitches((prev) =>
                    prev.map((sw, i) => ({
                        ...sw,
                        value: response[currentHW.variables[i]]?.value === 'true',
                    }))
                );
                setServerValue(parseInt(response.security_code?.value, 10) || 0);
                setRestartNumber(response.Restart_number?.value || -1);
                setHeKapu1(response.HE_kapu_1?.value === 'true'); // Boolean-ként dolgozunk
                setHeAjto(response.He_ajto?.value === 'true'); // Már boolean

                if (response.Temp_BMP !== undefined) {
                    setTemperatureBMP(parseFloat(response.Temp_BMP.value));
                }
                if (response.Press_BMP !== undefined) {
                    setPressBMP(parseFloat(response.Press_BMP.value));
                }
                if (response.Humi_AHT !== undefined) {
                    setHumiAHT(parseFloat(response.Humi_AHT.value));
                }
                if (response.Temp_AHT !== undefined) {
                    setTemperatureAHT(parseFloat(response.Temp_AHT.value));
                }
            }
        })
        .catch((error) => {
            console.error('Hiba a fetchInitialState során:', error);
        });
    };


  useEffect(() => {
    fetchInitialState();
    const heartbeatInterval = setInterval(fetchHeartbeat, 2000);
    const updateInterval = setInterval(() => {
      fetchInitialState();
    }, 5000);
    return () => {
      clearInterval(heartbeatInterval);
      clearInterval(updateInterval);
    };
  }, []);

  return (
    <div className="pageContainer">
      <div className="headerRow">
        <button className="backButton" onClick={() => navigate('/')}>
          Vissza
        </button>
        <h2 className="text">Hegyalja utca X3.10</h2>
        <div className={`lamp ${heartbeat ? 'active' : ''}`}></div>
      </div>




      <div className="toggleContainer">
  <div className="toggleRow">
    {switches.slice(0, 3).map((sw, i) => (
      <label className="toggleContainer" key={i}>
        {sw.label}
        <input
          type="checkbox"
          checked={sw.value}
          onChange={() => toggleSwitch(i)}
          className="customSwitch"
        />
      </label>
    ))}
  </div>
  <div className="toggleRow single">
    <label className="toggleContainer">
      {switches[3].label}
      <input
        type="checkbox"
        checked={switches[3]?.value}
        onChange={() => toggleSwitch(3)}
        className="customSwitch"
      />
    </label>
  </div>
</div>
 




      <div className="securityCodeRow">
        <h2>Biztonsági kód</h2>
        <div style={{ margin: '0 10px' }}>
          <button onClick={() => changeValue(-1)} className="counterButtonSmall">
            -
          </button>
        </div>
        <p className="counterTextSmall">{serverValue}</p>
        <div style={{ margin: '0 10px' }}>
          <button onClick={() => changeValue(1)} className="counterButtonSmall">
            +
          </button>
        </div>
      </div>
      <div className="switchRow">
        <div
          className="switchContainer" onClick={() => sendDataToServer('Restart_number', 0)}
        >
          <h3>Restart</h3>
          <p>{restartNumber}</p>
        </div>
        <div
          className="switchContainer"
          onClick={() => {
              const newValue = !heKapu1; // Kapu állapot váltása
              setHeKapu1(newValue); // Állapot frissítése
              sendDataToServer('HE_kapu_1', newValue ? 'true' : 'false'); // Új érték elküldése
          }}
        >
          <h3>Kapu</h3>
          <p>{heKapu1 ? 'Nyitva' : 'Zárva'}</p>
        </div>
        <div
          className="switchContainer"
          onClick={() => {
              const newValue = !heAjto; // Ajtó állapot váltása
              setHeAjto(newValue); // Állapot frissítése
              sendDataToServer('He_ajto', newValue ? 'true' : 'false'); // Új érték elküldése
          }}
        >
          <h3>Ajtó</h3>
          <p>{heAjto ? 'Nyitva' : 'Zárva'}</p>
        </div>
      </div>
      <div className="temperatureRow">
        <div className="temperatureBox">
          <h3>Temp_BMP</h3>
          <p>{temperatureBMP !== null ? `${temperatureBMP} °C` : 'N/A'}</p>
        </div>
        <div className="temperatureBox">
          <h3>Temp_AHT</h3>
          <p>{temperatureAHT !== null ? `${temperatureAHT} °C` : 'N/A'}</p>
        </div>
      </div>
      <div className="temperatureRow">
        <div className="temperatureBox">
          <h3>Press_BMP</h3>
          <p>{pressBMP !== null ? `${pressBMP} hPa` : 'N/A'}</p>
        </div>
        <div className="temperatureBox">
          <h3>Humi_AHT</h3>
          <p>{humiAHT !== null ? `${humiAHT} %` : 'N/A'}</p>
        </div>
      </div>
      <div className="responseContainer">
        <p className="responseText">{responseText}</p>
        <p className="responseText">Lekér: {lastRequestTime}</p>
      </div>
    </div>
  );
};

export default HegyaljaPage;
